@import "bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap");
@import url("https://kit.fontawesome.com/e6d5bc7aed.css");
$primary: #6363b4;
$secondary: desaturate(lighten($primary, 20%), 20%);
$success: #41f3bf;
$link: #8ab4f8;
//$bg: darken(#171a1c, 2%);
$bg: desaturate(darken($primary, 47%), 80%);
$border: desaturate(darken($primary, 35%), 30%);

html {
  background: $bg;
}

.border,
.border-top,
.border-bottom,
.border-end,
.border-start,
.card,
.card-body {
  border-color: $border !important;
}

.bg-standard {
  background: $bg !important;
}

.mb-n-1px {
  margin-bottom: -1px;
}

.card {
  background: $bg !important;
}

.card-body {
  background: none !important;
}

body {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  overflow-x: hidden !important;
  background: $bg;
}

@media (min-width: 992px) {
  .px-lg-6 {
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 700;
}

small {
  font-weight: 300;

  &.tiny {
    font-size: 70%;
  }
}

.offset-thumb {
  margin-left: 49px;
}

@media screen and (min-width: 991px) {
  .offset-thumb {
    margin-left: 78px;
  }
}

.profile-thumb {
  max-width: 40px;
}

@media screen and (min-width: 991px) {
  .profile-thumb {
    max-width: 60px;
  }
}

.container-fluid {
  max-width: 1600px;
}

.pfp_img {
  -webkit-mask-image: url('clip.png');
  /* for Safari */
  mask-image: url('clip.png');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain;
  aspect-ratio: 1 / 1;
}

[data-bs-theme="dark"] {
  --bs-body-bg: #171a1c !important;
  --bs-body-color: #c6cdd2 !important;
  --bs-border-color: #343b3e !important;
  --bs-btn-bg: #6363b4 !important;
  --bs-btn-border-color: #6363b4 !important;
  --bs-primary: #6363b4 !important;
  --bs-accordion-active-bg: #6363b4 !important;
  --bs-danger-rgb: 244, 57, 76 !important;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: $bg;
}

.accordion-button {
  --bs-accordion-btn-bg: rgba(0, 0, 0, 0.2) !important;
  --bs-accordion-btn-color: var(--bs-emphasis-color) !important;

  &:not(.collapsed) {
    --bs-accordion-active-bg: #6363b4 !important;
    --bs-accordion-active-color: #ffffff !important;
  }
}

.btn-xs {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.75rem;
}

.text-success {
  color: #41f3bf !important;
}

.bg-success {
  background-color: #41f3bf !important;
}

.border-success {
  border-color: #41f3bf !important;
}

.border-left-success {
  border-left: solid 10px #41f3bf !important;
}

.border-left-info {
  border-left: solid 10px #0dcaf0 !important;
}

.border-left-warning {
  border-left: solid 10px #ffc107 !important;
}

.border-left-danger {
  border-left: solid 10px rgb(var(--bs-danger-rgb)) !important;
}

a {
  color: $link;

  &:hover {
    color: lighten($link, 5%);
  }
}

.text-bg-primary {
  color: desaturate(lighten($primary, 50%), 30%) !important;
  background: $primary !important;
  border-color: $primary !important;
}

.btn-primary {
  color: desaturate(lighten($primary, 50%), 30%) !important;
  background: $primary !important;
  border-color: $primary !important;

  &:hover,
  &:focus,
  &.active {
    color: desaturate(lighten($primary, 50%), 30%) !important;
    background: lighten($primary, 10%) !important;
    border-color: lighten($primary, 10%) !important;
  }
}

.btn-secondary {
  color: desaturate(darken($secondary, 80%), 30%) !important;
  background: $secondary !important;
  border-color: $secondary !important;

  &:hover,
  &:focus,
  &.active {
    color: desaturate(darken($secondary, 80%), 30%) !important;
    background: lighten($secondary, 10%) !important;
    border-color: lighten($secondary, 10%) !important;
  }

  &.disabled {
    color: desaturate(darken($secondary, 50%), 30%) !important;
    text-decoration: line-through;
  }
}

.btn-outline-secondary {
  color: desaturate(lighten($secondary, 10%), 30%) !important;
  background: none !important;
  border-color: $border !important;

  &:hover,
  &:focus,
  &.active {
    color: desaturate(darken($secondary, 80%), 30%) !important;
    background: $secondary !important;
    border-color: $secondary !important;
  }

  &.disabled {
    color: desaturate(lighten($secondary, 10%), 30%) !important;
    text-decoration: line-through;
  }
}

.btn-success {
  color: desaturate(darken($success, 50%), 30%) !important;
  background: $success !important;
  border-color: $success !important;

  &:hover,
  &:focus,
  &.active {
    color: desaturate(darken($success, 50%), 30%) !important;
    background: lighten($success, 20%) !important;
    border-color: lighten($success, 20%) !important;
  }

  /*
  --bs-btn-color: #000;
  --bs-btn-bg: #41f3bf;
  --bs-btn-border-color: #41f3bf;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #6cffd3;
  --bs-btn-hover-border-color: #6cffd3;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #6cffd3;
  --bs-btn-active-border-color: #6cffd3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #41f3bf;
  --bs-btn-disabled-border-color: #41f3bf;*/
}

.btn-tiny {
  padding: 4px 6px;
  font-size: 12px;
}

.text-brand {
  color: #6363b4;
}

.h-text {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6363b4;
  --bs-btn-border-color: #6363b4;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5d83fe;
  --bs-btn-hover-border-color: #5d83fe;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5d83fe;
  --bs-btn-active-border-color: #5d83fe;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6363b4;
  --bs-btn-disabled-border-color: #6363b4;
}

.cursor-pointer {
  cursor: pointer !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.btn-link {
  color: lighten($border, 20%) !important;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus,
  &.active {
    color: $secondary !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

.manage {
  min-width: 250px;
}

.reply-border {
  border-left: solid 20px $border !important;
}

.wallet-btn {
  display: none;
  min-width: 140px !important;
}

.cursor-default {
  cursor: default !important;
}

.profile-img {
  min-height: 120px;
}

.bg-thumb {
  background: $bg !important;
}

.edit-pfp {
  display: block;
  border-radius: 100%;
  line-height: 100%;
  position: relative;
  background: $primary;

  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1;
    margin-left: 3px;
  }

  img {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    position: relative;
    z-index: 2;
  }

  &:hover {
    img {
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }
}

* {
  scrollbar-width: 4px;
  scrollbar-color: $primary $bg;
}

.mod-scrollbar {
  padding-right: 1px !important;
}

.mod-scrollbar::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: none !important;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: 10px;
  border: 1px none $bg;
}

.vh-100 {
  height: auto !important;
}

@media screen and (max-width: 991px) {
  body {
    padding-bottom: 164px;
  }

  .rounded-nav {
    border-radius: 0 0 0 100%;
    padding: 20px 20px 30px 30px !important;
  }

  .share-base {
    margin: 0;
    padding: 0rem 1rem 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    width: 100%;
    background: #070708;
    box-shadow: 0px -10px 20px -10px $primary;
    display: flex !important;

    .btn {
      width: auto !important;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      margin: 0 auto;
    }

    /*
    .position-share {
      position: fixed;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
    }*/
  }
}

@media screen and (min-width: 991px) {
  .vh-100 {
    height: 100vh !important;
  }
}

.beta {
  font-size: 8px !important;
  line-height: 5px;
  padding: 8px 8px 8px 12px;
  border-radius: 10px 3px 3px 10px;
  display: block;
  top: 29%;
  right: -13%;
  transform: rotate(-4deg);
  background-color: #0dcaf0;
  color: #000;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.1);
  position: absolute;
  z-index: -1;
}

.bg-nfd {
  background-image: url(nfd-brand-bg.webp);
  background-size: cover;
  background-position: center center;
}

.bg-sb {
  background-color: #000;
  background-image: url(profit-bg.jpg);
  background-size: cover;
  background-position: center center;
}

.bg-shufl {
  background-image: url(shuflbg.png);
  background-size: cover;
  background-position: center center;
}

.shadow-inset {
  box-shadow: inset 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.bg-darker {
  background: darken($bg, 0.5%);
}

.bg-darkest {
  background: darken($bg, 1.5%);
}

.modal-backdrop.show {
  backdrop-filter: blur(4px) !important;
  opacity: 0.9;
}

.pb-45 {
  padding-bottom: 30px;
}

textarea {
  resize: none !important;

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}

.text-editor:has(> textarea:focus) {
  box-shadow: 0 0 20px rgba($primary, 0.3);
  border-color: $primary !important;
}

nav#nav {
  display: none !important;
}

.c-tooltip {
  position: relative;
  display: inline-block;
}

.c-tooltip .tooltiptext {
  visibility: hidden;
  width: max-content;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: #000;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  position: absolute;
  z-index: 1;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
  }
}

.c-tooltip:hover .tooltiptext {
  visibility: visible;
}